import React, { ReactElement } from 'react';
import styled from 'styled-components';
import logo from '../Images/tukio_web_logo.svg';

const LogoWrapper = styled.div`
    margin-bottom: 20px;
    margin-top: -50px;
`;

const Logo: React.FC = (): ReactElement => {
    return (
        <LogoWrapper>
            <img src={logo} alt="logo" />
        </LogoWrapper>
    );
};

export default Logo;
