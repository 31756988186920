import Background from './Images/tukio_web_bg.svg';

export const theme = {
    background: Background,
    colours: {
        primary: '#FF4C40',
        primaryDark: '#a20101',
    },
    font: {
        primary: {
            family: 'Nunito',
            colour: 'white',
        },
        secondary: {
            colour: 'darkorange',
        },
        error: 'darkorange',
        success: 'green',
    },
};
