import styled from 'styled-components';
import FormControl from 'react-bootstrap/FormControl';
import { device } from '../BasicComponents/device';

const EmailEntryInput = styled(FormControl)`
    height: 50px;
    padding-left: 10px;
    width: 225px !important;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    font-size: 13.5px;
    border-style: solid;
    border-width: 1px;
    border-color: ${(props) => props.theme.colours.primary};

    :focus {
        -webkit-box-shadow: none;
        box-shadow: none;
        border-style: solid;
        border-width: 1px;
        border-color: ${(props) => props.theme.colours.primary};
    }

    @media ${device.laptop} {
        width: 300px !important;
        padding-left: 20px;
        padding-right: 20px;
    }

    @media ${device.desktop} {
        width: 300px !important;
        padding-left: 20px;
        padding-right: 20px;
    }
`;

export default EmailEntryInput;
