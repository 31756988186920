import React, { ReactElement } from 'react';
import styled from 'styled-components';
import { device } from '../BasicComponents/device';

const DescHeader = styled.div`
    font-size: 40px;
    line-height: 45px;
    margin-bottom: 10px;

    @media ${device.desktop}, ${device.laptop} {
        margin-bottom: 0;
    }
`;

const DescBody = styled.div`
    @media ${device.desktop}, ${device.laptop} {
        font-size: 20px;
        max-width: 500px;
        margin: 20px auto;
    }
`;

const DescStrong = styled.span`
    font-weight: bold;
`;

const DescWrapper = styled.div`
    margin-bottom: 20px;
    text-align: center;
    font-weight: normal;
    padding-left: 10px;
    padding-right: 10px;
    
    @media ${device.desktop}, ${device.laptop} {
        width: 100%;
    }
`;

const Desc: React.FC = (): ReactElement => {
    return (
        <DescWrapper>
            <DescHeader>Create and share the moments in life.</DescHeader>
            <DescBody>
                <DescStrong>Tukio </DescStrong>
                <span>is a new app that helps you seamlessly manage personal time and create shared&nbsp;experiences.</span>
            </DescBody>
        </DescWrapper>
    );
};

export default Desc;
