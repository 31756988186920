import React, { ReactElement } from 'react';
import { ThemeProvider } from 'styled-components';
import EarlyAccessForm from './EarlyAccessForm/EarlyAccessForm';
import Logo from './BasicComponents/Logo';
import Desc from './BasicComponents/Desc';
import { Content } from './BasicComponents/Wrapper';
import { theme } from './theme';
import ComingSoon from './BasicComponents/ComingSoon';
import './App.css';


const App: React.FC = (): ReactElement => {
    return (
        <ThemeProvider theme={theme}>
            <Content>
                <Logo />
                <Desc />
                <EarlyAccessForm />
                <ComingSoon />
            </Content>
        </ThemeProvider>
    );
};

export default App;
