import styled from 'styled-components';

const Error = styled.div`
    text-align: center;
    font-size: 13.5px;
    margin-top: 15px;
    color: ${(props) => props.theme.font.error}; ;
`;

export default Error;
