import React, { ReactElement } from 'react';
import { useForm } from 'react-hook-form';
import Spinner from 'react-bootstrap/Spinner';
import InputGroup from 'react-bootstrap/InputGroup';
import Form from 'react-bootstrap/Form';
import EmailEntryInput from './EmailEntryInput';
import EmailSubmitButton from './EmailSubmitButton';
import MessageContainer from './MessageContainer';
import { useSubmit } from '../Hooks/SubmitHook';
import { EarlyAccess } from '../Services/EarlyAccessService';

const EarlyAccessForm: React.FC = (): ReactElement => {
    const { reset, submit, isLoading, subscriptionError, subscription } = useSubmit();
    const { register, handleSubmit, errors } = useForm<EarlyAccess>();

    const emailValidationMessage =
        errors.email && errors.email.type.toLowerCase() === 'required' ? 'Please enter your email' : '';

    const onSubmit = async (data: EarlyAccess) => {
        await submit(data);
    };

    return (
        <>
            <Form onSubmit={handleSubmit(onSubmit)} onChange={reset}>
                <InputGroup className="mb-3">
                    <EmailEntryInput
                        placeholder="Enter email address"
                        aria-label="Email"
                        aria-describedby="Email"
                        name="email"
                        id="email"
                        ref={register({ required: true })}
                    />
                    <InputGroup.Append>
                        <EmailSubmitButton disabled={isLoading} type="submit" variant="outline-secondary">
                            {isLoading ? (
                                <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                            ) : (
                                'Get early access'
                            )}
                        </EmailSubmitButton>
                    </InputGroup.Append>
                </InputGroup>
                <MessageContainer
                    subscriptionError={subscriptionError}
                    emailValidateMessage={emailValidationMessage}
                    subscriptionMessage={subscription.msg}
                />
            </Form>
        </>
    );
};

export default EarlyAccessForm;
