import { useState } from 'react';
import { Subscription, EarlyAccess, subscribeToEarlyAccess } from '../Services/EarlyAccessService';
import * as EmailValidator from 'email-validator';

interface SubmitResult {
    subscription: Subscription;
    subscriptionError: string;
    isLoading: boolean;
    submit: (data: EarlyAccess) => Promise<void>;
    reset: () => void;
}

const getErrorMessage = (subscriptionResult: string, email: string): string => {
    if (subscriptionResult.toUpperCase().includes(email.toUpperCase())) {
        return "Good news! You're already subcribed!";
    } else {
        return "Subscription failed! Don't worry, we're on it!";
    }
};

export const useSubmit = (): SubmitResult => {
    const [subscription, setData] = useState<Subscription>({ result: '', msg: '' });
    const [subscriptionError, setSubscriptionError] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const submit = async (data: EarlyAccess): Promise<void> => {
        reset();
        setIsLoading(true);
        const isEmailValid: boolean = EmailValidator.validate(data.email);
        if (isEmailValid) {
            await subscribeToEarlyAccess(data)
                .then((response) => {
                    if (response.result.toUpperCase() === 'ERROR') {
                        const errorMessage = getErrorMessage(response.msg, data.email);
                        setSubscriptionError(errorMessage);
                    } else {
                        response.msg = 'Thank you for subscribing!';
                        setData(response);
                    }
                })
                .catch((error) => {
                    setSubscriptionError("Something bad happened! Don't worry, we're on it!");
                    console.debug('error', error);
                });
        } else {
            setSubscriptionError('Please provide a valid email.');
        }
        setIsLoading(false);
    };

    const reset = (): void => {
        setSubscriptionError('');
        setIsLoading(false);
        setData({ result: '', msg: '' });
    };

    return {
        subscription,
        subscriptionError,
        isLoading,
        submit,
        reset,
    };
};
