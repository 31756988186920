import axios from 'axios';

export type EarlyAccess = {
    email: string;
};

export interface Subscription {
    result: string;
    msg: string;
}

export const subscribeToEarlyAccess = async (data: EarlyAccess): Promise<Subscription> => {
    const result = await axios({
        method: 'POST',
        url: 'https://xduocxd034.execute-api.eu-west-2.amazonaws.com/prod/subscribe',
        data: data,
    });
    const resultString = JSON.stringify(result.data);
    const cleanedUpResultString = escapeSpecialChars(resultString);

    return JSON.parse(cleanedUpResultString);
};

const escapeSpecialChars = (jsonString: string): string => {
    return jsonString
        .replace(/\\n/g, '\\n')
        .replace(/\\'/g, "\\'")
        .replace(/\\"/g, '\\"')
        .replace(/\\&/g, '\\&')
        .replace(/\\r/g, '\\r')
        .replace(/\\t/g, '\\t')
        .replace(/\\b/g, '\\b')
        .replace(/\\f/g, '\\f');
};
