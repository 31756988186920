import React, { ReactElement } from 'react';
import styled from 'styled-components';
import Success from '../BasicComponents/Success';
import Error from '../BasicComponents/Error';

const MessageContainerContent = styled.div`
    height: 50px;
    width: fit-content;
    margin: 0 auto;
`;

const MessageContainerWrapper = styled.div`
    background-color: rgba(0, 0, 0, 0.4);
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 8px;
`;

interface MessageContainerProps {
    subscriptionError: string;
    emailValidateMessage: string;
    subscriptionMessage: string;
}

const MessageContainer: React.FC<MessageContainerProps> = ({
    subscriptionError,
    emailValidateMessage,
    subscriptionMessage,
}: MessageContainerProps): ReactElement => {
    return (
        <MessageContainerContent>
            <MessageContainerWrapper>
                {emailValidateMessage && <Error>{emailValidateMessage}</Error>}
                {subscriptionError && <Error>{subscriptionError}</Error>}
                {subscriptionMessage && <Success>{subscriptionMessage}</Success>}
            </MessageContainerWrapper>
        </MessageContainerContent>
    );
};

export default MessageContainer;
