import styled from 'styled-components';
import { device } from '../BasicComponents/device';

export const Content = styled.div`
    background-image: url(${(props) => props.theme.background});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-attachment: fixed;
    /* font-size: calc(10px + 2vmin); */
    color: ${(props) => props.theme.font.primary.colour};
    font-family: ${(props) => props.theme.font.primary.family};
    min-height: 100vh;
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin: auto;
    overflow: hidden;
    

    @media ${device.laptop} {
        max-width: 2000px;
    }

    @media ${device.desktop} {
        max-width: 2400px;
    }
`;
