import styled from 'styled-components';
import Button from 'react-bootstrap/Button';
import { device } from '../BasicComponents/device';

const EmailSubmitButton = styled(Button)`
    height: 50px;
    color: ${(props) => props.theme.font.primary.colour};
    border: 1;
    border-color: ${(props) => props.theme.colours.primary};
    background: ${(props) => props.theme.colours.primary};
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    -webkit-appearance: none;
    font-weight: bold;
    font-size: 13.5px;
    width: 130px;
    white-space: nowrap;
    -webkit-tap-highlight-color: ${(props) => props.theme.colours.primaryDark};

    :hover {
        background: ${(props) => props.theme.colours.primaryDark};
        color: ${(props) => props.theme.font.primary.colour};
        border-color: ${(props) => props.theme.colours.primaryDark};
    }
    :disabled {
        background: ${(props) => props.theme.colours.primaryDark};
        color: ${(props) => props.theme.font.primary.colour};
        border-color: ${(props) => props.theme.colours.primaryDark};
    }

    @media ${device.laptop} {
        width: 150px;
    }

    @media ${device.desktop} {
        width: 150px;
    }
`;

export default EmailSubmitButton;
