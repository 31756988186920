import React, { ReactElement } from 'react';
import styled from 'styled-components';
import { device } from './device';
import comingSoonApple from '../Images/app_store_coming_soon.png'
import comingSoonPlayStore from '../Images/google_play_coming_soon.png'

const ComingSoonContent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 13vh;

    @media ${device.desktop}, ${device.laptop} {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        height: inherit;
        width: 335px;
    }
`

const ComingSoon: React.FC = (): ReactElement => {
    return (
        <ComingSoonContent>
            <img src={comingSoonApple} alt="coming to the Apple app store soon" />
            <img src={comingSoonPlayStore} alt="coming to the Google play store soon" />
        </ComingSoonContent>
    );
};

export default ComingSoon;