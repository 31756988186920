import styled from 'styled-components';

const Success = styled.div`
    text-align: center;
    font-size: 13.5px;
    margin-top: 15px;
    color: ${(props) => props.theme.font.primary.colour}; ;
`;

export default Success;
